import $ from 'jquery';
import prestashop from 'prestashop';

/**
 * Update address form on country change
 * Emit "addressFormUpdated" event
 *
 * @param selectors
 */
function handleCountryChange(selectors) {
  $('body').on('change', selectors.country, (e) => {
    const $countrySelect = $(e.currentTarget);
    const $addressWrapper = $countrySelect.closest(selectors.address);

    const requestData = {
      id_country: $countrySelect.val(),
      id_address: $addressWrapper.data('id-address'),
    };
    const getFormViewUrl = $addressWrapper.data('refresh-url');

    $.post(getFormViewUrl, requestData).then((resp) => {
      const inputs = [];

      // Store fields values before updating form
      $addressWrapper.find('input').each(function () {
        inputs[$(this).prop('name')] = $(this).val();
      });

      const $newContent = $(resp.address_form);
      $addressWrapper.replaceWith($newContent);

      // Restore fields values
      $newContent.find('input').each(function () {
        $(this).val(inputs[$(this).prop('name')]);
      });

      prestashop.emit('updatedAddressForm', {target: $newContent, resp});
    }).fail((resp) => {
      prestashop.emit('handleError', {eventType: 'updateAddressForm', resp});
    });
  });
}

$(document).ready(() => {
  handleCountryChange({
    country: '.js-country',
    address: '.js-address-form',
  });
});
